import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy, OnChanges, OnDestroy, ElementRef, ViewChild } from '@angular/core'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'
import { ReportProblemPopupComponent } from '../../popups/Report-Dialogs/report-problem/report-problem.component'
import { IQuestion } from 'src/app/models/Question.model'
import { AudioService } from 'src/app/services/audio.service'
import { DefaultPopupSettings } from 'src/app/models/Dialog.model'
import { of } from 'rxjs'
import { delay, take } from 'rxjs/operators'
import { TranslateService } from '@ngx-translate/core'
import { RandomizeService } from 'src/app/services/randomize.service'
import { LocalizeHelperService } from 'src/app/services/localizHelper.service'

@Component({
  selector: 'speaking-game',
  templateUrl: './speaking-game.component.html',
  styleUrls: ['./speaking-game.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpeakingGameComponent implements OnChanges, OnInit, OnDestroy {
  @Input() question: IQuestion
  @Input() test: boolean
  @Input() game: boolean
  @Input() comparison: boolean
  @Output() success = new EventEmitter()
  @Output() failed = new EventEmitter()

  @ViewChild('audiohelp') audiohelp: ElementRef

  public currentlang

  constructor(private _dialog: MatDialog, public translate: TranslateService, private _RandomizeService: RandomizeService, private localizeHelperService: LocalizeHelperService, private audioService: AudioService) {}

  public checked = false
  public selectedAnswer: boolean = null
  public imgType
  public cardColors = []
  public questionId: number
  public timerId

  /* Clear selected answer and checked answer when inputs are changed */
  ngOnChanges() {
    this.selectedAnswer = null
    this.checked = false
    of(true)
      .pipe(delay(500), take(1))
      .subscribe(() => this.audioService.playAudio(this.question.audioSrc, this.question.audioSrc))
  }

  ngOnInit() {
    this.currentlang = this.localizeHelperService.getLangForced()

    this.questionId = this.question.id

    this.audioService.preloadAudio(this.question.audioSrc, this.question.audioSrc)

    of(true)
      .pipe(delay(500), take(1))
      .subscribe(() => this.audioService.playAudio(this.question.audioSrc, this.question.audioSrc))

    this.cardColors = this._RandomizeService.getRandom12Colors()
  }

  ngOnDestroy() {
    clearTimeout(this.timerId)
    this.audioService.stopAllAudio()
  }

  public selectAnswer(answer) {
    if (this.checked === true) return
    this.selectedAnswer = answer
  }

  public playAudio(src) {
    this.audioService.playAudio(src, src)
  }

  public checkAnswer() {
    this.checked = true
    if (true === this.selectedAnswer) {
      this.audioService.playSoundCorrect()
    } else {
      this.audioService.playSoundIncorrect()
    }
  }

  public get checkRightAnswer() {
    return this.selectedAnswer === true
  }

  public setAnswer(answer) {
    if (this.checkRightAnswer)
      this.success.emit({
        question: this.question,
        answer: true,
      })
    else this.failed.emit({ question: this.question, answer: false })
  }

  public setAnswerTest(answer) {
    if (this.checkRightAnswer)
      this.success.emit({
        question: this.question,
        answer: true,
      })
    else this.failed.emit({ question: this.question, answer: false })
  }

  public openReportPopup(question) {
    this._dialog.open(ReportProblemPopupComponent, new DefaultPopupSettings(question))
  }
}
