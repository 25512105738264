<div *ngIf="floatTextActive" class="floatMsg">
  <span>{{ floatText }}</span>
  <img src="/assets/images/icons/coin.svg" alt="coin icon" />
</div>
<section class="games" *ngIf="(lesson$ | async)?.type.name === 'Game'">
  <div class="progress" (click)="closeToolTip()">
    <div>
      <div>
        <div>
          <div>
            <img class="logo" src="/assets/images/logos/logo-white-bg.svg" alt="DinoLingo Logo" />
          </div>
        </div>
        <div class="title">
          <span class="baloo">{{ formatCourseName((lesson$ | async)?.name) }}</span>
        </div>
      </div>
    </div>

    <button appSounds mat-icon-button (click)="close()" color="warn">
      <img src="/assets/images/icons/closeIcon.svg" alt="close" />
    </button>
    <section class="progressBar">
      <img src="/assets/images/progress-bar/start.svg" alt="start-progress-bar" />

      <ng-container *ngFor="let next of currentPosition; let i = index">
        <span class="compl"></span>
      </ng-container>

      <img src="/assets/images/progress-bar/current.svg" alt="current-progress-bar" />

      <ng-container *ngFor="let next of totalPosition; let i = index">
        <span *ngIf="i > 0" class="uncompl"></span>
      </ng-container>

      <img src="/assets/images/progress-bar/end.svg" alt="end-progress-bar" />
    </section>
  </div>

  <ng-container *ngIf="['ttt_target', 'ttt_local', 'ata_target', 'ata_local', 'tta_local', 'att_local'].includes((lesson$ | async).questions[currentGame]?.questionType.slug)">
    <app-localization-dropdown [locales]="availableLocales" [default]="getDefaultLocale()" (setTranslation)="setLanguageTranslation($event)"></app-localization-dropdown>
  </ng-container>

  <audio-to-text
    class="game"
    *ngIf="(lesson$ | async).questions[currentGame]?.questionType.slug === 'att_target' || (lesson$ | async).questions[currentGame]?.questionType.slug === 'att_local'"
    [question]="(lesson$ | async).questions[currentGame]"
    [game]="true"
    [course]="course$ | async"
    (success)="success($event)"
    (failed)="failed($event)"
    [localizations]="localizations"
    [translation]="selectedTranslation"
  >
  </audio-to-text>

  <text-to-audio
    class="game"
    *ngIf="(lesson$ | async).questions[currentGame]?.questionType.slug === 'tta_target' || (lesson$ | async).questions[currentGame]?.questionType.slug === 'tta_local'"
    [question]="(lesson$ | async).questions[currentGame]"
    [game]="true"
    [course]="course$ | async"
    (success)="success($event)"
    (failed)="failed($event)"
    [localizations]="localizations"
    [translation]="selectedTranslation"
  >
  </text-to-audio>

  <fill-the-blank class="game" *ngIf="(lesson$ | async).questions[currentGame]?.questionType.slug === 'ftb'" [question]="(lesson$ | async).questions[currentGame]" [game]="true" (success)="success($event)" (failed)="failed($event)"> </fill-the-blank>

  <what-come-after class="game" *ngIf="(lesson$ | async).questions[currentGame]?.questionType.slug === 'wca'" [question]="(lesson$ | async).questions[currentGame]" [game]="true" (success)="success($event)" (failed)="failed($event)">
  </what-come-after>

  <picture-to-audio
    class="game"
    *ngIf="(lesson$ | async).questions[currentGame]?.questionType.slug === 'pta'"
    [question]="(lesson$ | async).questions[currentGame]"
    [game]="true"
    [comparison]="useAnimComparisonFix"
    (success)="success($event)"
    (failed)="failed($event)"
  >
  </picture-to-audio>
  <audio-to-picture
    class="game"
    *ngIf="(lesson$ | async).questions[currentGame]?.questionType.slug === 'atp'"
    [question]="(lesson$ | async).questions[currentGame]"
    [game]="true"
    [comparison]="useAnimComparisonFix"
    (success)="success($event)"
    (failed)="failed($event)"
  >
  </audio-to-picture>
  <click-and-tell
    class="game"
    *ngIf="(lesson$ | async).questions[currentGame]?.questionType.slug === 'sas'"
    [question]="(lesson$ | async).questions[currentGame]"
    [game]="true"
    [comparison]="useAnimComparisonFix"
    (success)="success($event)"
    (failed)="failed($event)"
  >
  </click-and-tell>
  <speaking-game
    class="game"
    [question]="(lesson$ | async).questions[currentGame]"
    *ngIf="(lesson$ | async).questions[currentGame]?.questionType.slug === 'speaking'"
    [game]="true"
    (success)="success($event)"
    (failed)="failed($event)"
  >
  </speaking-game>
  <correct-or-incorrect
    class="game"
    [question]="(lesson$ | async).questions[currentGame]"
    *ngIf="(lesson$ | async).questions[currentGame]?.questionType.slug === 'tof'"
    [game]="true"
    [comparison]="useAnimComparisonFix"
    (success)="success($event)"
    (failed)="failed($event)"
  >
  </correct-or-incorrect>
  <memory-cards
    class="game"
    [question]="(lesson$ | async).questions[currentGame]"
    *ngIf="(lesson$ | async).questions[currentGame]?.questionType.slug === 'mem'"
    [game]="true"
    [comparison]="useAnimComparisonFix"
    (success)="success($event)"
    (failed)="failed($event)"
  >
  </memory-cards>
  <text-to-text
    class="game"
    [question]="(lesson$ | async).questions[currentGame]"
    *ngIf="['ttt_local', 'ttt_target'].includes((lesson$ | async).questions[currentGame]?.questionType.slug)"
    [game]="true"
    (success)="success($event)"
    (failed)="failed($event)"
    [localizations]="localizations"
    [translation]="selectedTranslation"
    [course]="course$ | async"
  ></text-to-text>
  <audio-to-audio
    class="game"
    [question]="(lesson$ | async).questions[currentGame]"
    *ngIf="['ata_local', 'ata_target'].includes((lesson$ | async).questions[currentGame]?.questionType.slug)"
    [game]="true"
    (success)="success($event)"
    (failed)="failed($event)"
    [localizations]="localizations"
    [translation]="selectedTranslation"
    [course]="course$ | async"
  ></audio-to-audio>
  <arrange-text
    class="game"
    [question]="(lesson$ | async).questions[currentGame]"
    *ngIf="(lesson$ | async).questions[currentGame]?.questionType.slug === 'art'"
    [game]="true"
    [course]="course$ | async"
    (success)="success($event)"
    (failed)="failed($event)"
  ></arrange-text>
</section>

<section class="book" *ngIf="(lesson$ | async)?.type.name === 'Book'">
  <div class="progress" (click)="closeToolTip()">
    <div>
      <div>
        <div>
          <div>
            <img class="logo" src="/assets/images/logos/logo-white-bg.svg" alt="DinoLingo Logo" />
          </div>
        </div>
        <div class="title">
          <span class="baloo">{{ formatCourseName((lesson$ | async)?.name) }}</span>
        </div>
      </div>
    </div>

    <button appSounds mat-icon-button (click)="close()" color="warn">
      <img src="/assets/images/icons/closeIcon.svg" alt="close" />
    </button>
  </div>

  <div class="bookContent">
    <img class="trees left" src="/assets/images/games/audio-to-picture/gameBackground.svg" alt="" />
    <img class="trees right" src="/assets/images/games/audio-to-picture/gameBackground2.svg" alt="" />
    <img class="trees center" src="/assets/images/games/audio-to-picture/gameBackground3.svg" alt="" />
    <book-player class="player" (newPageOpened)="newBookPageOpened()" (ended)="completeLesson()" [course]="course$ | async" [media]="(lesson$ | async).books[0]" (repeat)="completeLesson()"> </book-player>
  </div>
</section>

<section class="video" *ngIf="(lesson$ | async)?.type.name === 'Video' || (lesson$ | async)?.type.name === 'Video Song'">
  <div class="progress">
    <div>
      <div>
        <div>
          <div>
            <img class="logo" src="/assets/images/logos/logo-white-bg.svg" alt="DinoLingo Logo" />
          </div>
        </div>
        <div class="title">
          <span class="baloo">{{ formatCourseName((lesson$ | async)?.name) }}</span>
        </div>
      </div>
    </div>

    <button appSounds mat-icon-button (click)="close()" color="warn">
      <img src="/assets/images/icons/closeIcon.svg" alt="close" />
    </button>
  </div>

  <div class="video-cont test1">
    <div class="game-help-text">{{ 'LESSONS.VIDEO.WATCH' | translate }}</div>
    <img class="trees left" src="/assets/images/games/audio-to-picture/gameBackground.svg" alt=" " />
    <img class="trees right" src="/assets/images/games/audio-to-picture/gameBackground2.svg" alt=" " />
    <img class="trees center" src="/assets/images/games/audio-to-picture/gameBackground3.svg" alt=" " />
    <div class="video">
      <video
        (contextmenu)="noDownload($event)"
        #video
        id="video"
        class="player"
        disablePictureInPicture
        controlsList="nodownload"
        controls
        color="accent"
        [autoplay]="true"
        (play)="videoStarted(video)"
        (ended)="completeLesson()"
        [src]="(lesson$ | async)?.questions[0].src"
      >
        <ng-container *ngIf="(lesson$ | async)?.slug == 'en-fr-colors-1-video'">
          <track label="French" kind="subtitles" srclang="fr" src="/assets/subtitles/FRE-COLORS1.vtt" default />
        </ng-container>
    </video>
    </div>
  </div>
</section>

<section class="test" *ngIf="(lesson$ | async)?.type.name === 'Mini Test' || (lesson$ | async)?.type.name === 'Unit Test'">
  <div class="progress" (click)="closeToolTip()">
    <div>
      <div>
        <div>
          <div>
            <img class="logo" src="/assets/images/logos/logo-white-bg.svg" alt="DinoLingo Logo" />
          </div>
        </div>
        <div class="subline">
          <span (click)="changeToolTip($event)">
            <img class="audio" src="/assets/images/games/questionmar.png" alt="play-back-sound" />
          </span>
          <span class="tooltip" *ngIf="displayTooltip">{{ lessonShuffleTestArray[currentGame]?.questionType.description }}</span>
        </div>
        <div class="title">
          <span class="baloo">{{ formatCourseName((lesson$ | async)?.name) }}</span>
        </div>
      </div>
    </div>

    <button appSounds mat-icon-button (click)="close()" color="warn">
      <img src="/assets/images/icons/closeIcon.svg" alt="close" />
    </button>
    <section class="progressBar">
      <img src="/assets/images/progress-bar/start.svg" alt="start-progress-bar" />

      <ng-container *ngFor="let next of currentPosition; let i = index">
        <span class="compl"></span>
      </ng-container>

      <img src="/assets/images/progress-bar/current.svg" alt="current-progress-bar" />

      <ng-container *ngFor="let next of totalPosition; let i = index">
        <span *ngIf="i > 0" class="uncompl"></span>
      </ng-container>

      <img src="/assets/images/progress-bar/end.svg" alt="end-progress-bar" />
    </section>
  </div>

  <ng-container *ngIf="['ttt_target', 'ttt_local', 'ata_target', 'ata_local', 'tta_local', 'att_local'].includes(lessonShuffleTestArray[currentGame]?.questionType.slug)">
    <app-localization-dropdown [locales]="availableLocales" [default]="getDefaultLocale()" (setTranslation)="setLanguageTranslation($event)"></app-localization-dropdown>
  </ng-container>

  <picture-to-audio
    class="game"
    *ngIf="lessonShuffleTestArray[currentGame]?.questionType.slug === 'pta'"
    [question]="lessonShuffleTestArray[currentGame]"
    [test]="true"
    [comparison]="useAnimComparisonFix"
    (success)="success($event)"
    (failed)="failed($event)"
  >
  </picture-to-audio>
  <audio-to-picture
    class="game"
    *ngIf="lessonShuffleTestArray[currentGame]?.questionType.slug === 'atp'"
    [question]="lessonShuffleTestArray[currentGame]"
    [test]="true"
    [comparison]="useAnimComparisonFix"
    (success)="success($event)"
    (failed)="failed($event)"
  >
  </audio-to-picture>
  <click-and-tell
    class="game"
    *ngIf="lessonShuffleTestArray[currentGame]?.questionType.slug === 'sas'"
    [question]="lessonShuffleTestArray[currentGame]"
    [test]="true"
    [comparison]="useAnimComparisonFix"
    (success)="success($event)"
    (failed)="failed($event)"
  >
  </click-and-tell>
  <speaking-game
    class="game"
    [question]="lessonShuffleTestArray[currentGame]"
    *ngIf="lessonShuffleTestArray[currentGame]?.questionType.slug === 'speaking'"
    [test]="true"
    (success)="success($event)"
    (failed)="failed($event)"
  >
  </speaking-game>
  <correct-or-incorrect
    class="game"
    [question]="lessonShuffleTestArray[currentGame]"
    *ngIf="lessonShuffleTestArray[currentGame]?.questionType.slug === 'tof'"
    [test]="true"
    [comparison]="useAnimComparisonFix"
    (success)="success($event)"
    (failed)="failed($event)"
  >
  </correct-or-incorrect>
  <memory-cards
    class="game"
    *ngIf="lessonShuffleTestArray[currentGame]?.questionType.slug === 'mem'"
    [question]="lessonShuffleTestArray[currentGame]"
    [test]="true"
    [comparison]="useAnimComparisonFix"
    (success)="success($event)"
    (failed)="failed($event)"
  >
  </memory-cards>
  <text-to-text
    class="game"
    [question]="lessonShuffleTestArray[currentGame]"
    *ngIf="['ttt_local', 'ttt_target'].includes(lessonShuffleTestArray[currentGame]?.questionType.slug)"
    [test]="true"
    (success)="success($event)"
    (failed)="failed($event)"
    [localizations]="localizations"
    [translation]="selectedTranslation"
    [course]="course$ | async"
  ></text-to-text>
  <audio-to-audio
    class="game"
    [question]="lessonShuffleTestArray[currentGame]"
    *ngIf="['ata_local', 'ata_target'].includes(lessonShuffleTestArray[currentGame]?.questionType.slug)"
    [test]="true"
    (success)="success($event)"
    (failed)="failed($event)"
    [localizations]="localizations"
    [translation]="selectedTranslation"
    [course]="course$ | async"
  ></audio-to-audio>
  <arrange-text
    class="game"
    [question]="lessonShuffleTestArray[currentGame]"
    *ngIf="lessonShuffleTestArray[currentGame]?.questionType.slug === 'art'"
    [test]="true"
    [course]="course$ | async"
    (success)="success($event)"
    (failed)="failed($event)"
  ></arrange-text>
  <audio-to-text
    class="game"
    *ngIf="lessonShuffleTestArray[currentGame]?.questionType.slug === 'att_target' || lessonShuffleTestArray[currentGame]?.questionType.slug === 'att_local'"
    [question]="lessonShuffleTestArray[currentGame]"
    [test]="true"
    [course]="course$ | async"
    (success)="success($event)"
    (failed)="failed($event)"
    [localizations]="localizations"
    [translation]="selectedTranslation"
  ></audio-to-text>
  <text-to-audio
    class="game"
    *ngIf="lessonShuffleTestArray[currentGame]?.questionType.slug === 'tta_target' || lessonShuffleTestArray[currentGame]?.questionType.slug === 'tta_local'"
    [question]="lessonShuffleTestArray[currentGame]"
    [course]="course$ | async"
    [test]="true"
    (success)="success($event)"
    (failed)="failed($event)"
    [localizations]="localizations"
    [translation]="selectedTranslation"
  ></text-to-audio>
</section>
